import { extendTheme } from "@chakra-ui/react";

const theme = {
  fonts: {
    body: "Source Sans Pro, sans-serif",
    heading: "Source Sans Pro, sans-serif"
  },

  colors: {
    primary: "#89B222",
    secondary: "#454F4E",
    bg: "#F7F7F7",
    grey: "#918E8F",
    text: "#434242"
    
  }
};

export default extendTheme(theme);
