// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-strapi-blog-slug-tsx": () => import("./../../../src/pages/blog/{StrapiBlog.slug}.tsx" /* webpackChunkName: "component---src-pages-blog-strapi-blog-slug-tsx" */),
  "component---src-pages-gdpr-tsx": () => import("./../../../src/pages/gdpr.tsx" /* webpackChunkName: "component---src-pages-gdpr-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-objednavka-vytvorena-tsx": () => import("./../../../src/pages/objednavka-vytvorena.tsx" /* webpackChunkName: "component---src-pages-objednavka-vytvorena-tsx" */),
  "component---src-pages-vytvorit-objednavku-tsx": () => import("./../../../src/pages/vytvorit-objednavku.tsx" /* webpackChunkName: "component---src-pages-vytvorit-objednavku-tsx" */)
}

